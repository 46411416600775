<template>
  <div>
    <b-card :title="$t('profile.ProfileTitle')" class="profile-back">
      <div class="back-btn-update-profile">
        <b-button
          variant="outline-primary"
          class="v2-back"
          size="sm"
          :to="{ name: 'profile' }"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
        </b-button>
      </div>
      <div class="card-divider"></div>
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              v-if="user.profile_pic_url && !user.url"
              class="profile-pic"
              ref="previewEl"
              rounded
              :src="user.profile_pic_url"
            />
            <b-img
              v-if="user.url"
              class="profile-pic"
              ref="previewEl"
              rounded
              :src="user.url"
            />

            <AvatarImage v-if="!user.profile_pic_url && !user.url" />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75 custom-upload-button"
          >
            <b-form-file
              v-model="profileFile"
              class="custom-file-input"
              :placeholder="$t('account.CropPlaceholder')"
              drop-placeholder="Drop file here..."
              accept=".jpg, .png, .jpeg"
              @change="onFileSelect"
            />

            {{ $t("profile.Upload") }}
          </b-button>

          <b-modal
            v-model="modalShow"
            centered
            size="lg"
            modal-class="no-header-modal"
          >
            <!-- :dragMode="move" comment because getting of error -->
            <VueCropper
              :aspectRatio="1 / 1"
              :autoCropArea="0.65"
              :restore="false"
              :guides="false"
              :center="false"
              :highlight="false"
              :cropBoxMovable="true"
              :cropBoxResizable="false"
              :toggleDragModeOnDblclick="false"
              v-show="selectedFile"
              ref="cropper"
              :src="selectedFile"
              alt="Source Image"
            ></VueCropper>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="saveImage()"
            >
              {{ $t("account.Crop") }}</b-button
            >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              @click="cancelImage()"
              text
            >
              {{ $t("account.Cancel") }}</b-button
            >
          </b-modal>

          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png,.jpeg"
            :hidden="true"
            plain
          />

          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="resetImg"
          >
            {{ $t("profile.Reset") }}
          </b-button>
          <!--/ reset -->
          <b-card-text class="imgtext" v-if="profileFile">{{
            $t(profileFile.name)
          }}</b-card-text>
          <b-card-text class="imgtext" v-else>{{
            $t("profile.AllowedFile")
          }}</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <div class="">
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form
            class="mt-2 profile-page-forrm"
            @submit.prevent="updateUserProfile"
          >
            <div class="profile-scroll">
              <b-row class="">
                <b-col sm="6">
                  <b-form-group :label="$t('profile.FName')" label-for="f-name">
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      vid="first name"
                      :rules="{
                        required: true,
                        min: 2,
                        max: 50
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 && user.first_name.trim()
                        }"
                      >
                        <b-form-input
                          :placeholder="$t('profile.FName')"
                          name="first name"
                          v-model.trim="user.first_name"
                          :class="
                            errors.length == 0 && user.first_name
                              ? 'is-valid'
                              : null
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group :label="$t('profile.LName')" label-for="l-name">
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      vid="last name"
                      :rules="{
                        required: true,
                        min: 2,
                        max: 50
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid':
                            errors.length === 0 && user.last_name.trim()
                        }"
                      >
                        <b-form-input
                          name="last_name"
                          :placeholder="$t('profile.LName')"
                          v-model.trim="user.last_name"
                          :state="errors.length > 0 ? false : null"
                          :class="
                            errors.length == 0 && user.last_name
                              ? 'is-valid'
                              : null
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-group
                      :label="$t('profile.Email')"
                      label-for="e-mail"
                    >
                      <b-form-input
                        name="email"
                        :placeholder="$t('profile.Email')"
                        v-model="user.email"
                        :style="user.email ? 'text-transform: lowercase' : ''"
                        type="email"
                        :state="userEmailState"
                      />
                      <b-form-invalid-feedback>
                        {{
                          validationContext.errors[0]
                            ? handleError(validationContext.errors[0])
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="validationContext"
                    name="username"
                    :rules="{ required: true, min: 3 }"
                  >
                    <b-form-group
                      :label="$t('profile.Usernameinput')"
                      label-for="user-name"
                    >
                      <b-form-input
                        name="username"
                        :placeholder="$t('profile.Username')"
                        :style="
                          user.username ? 'text-transform: lowercase' : ''
                        "
                        v-model="user.username"
                        :state="requiredState('username')"
                        @input="trimSpaces"
                      />
                      <b-form-invalid-feedback>
                        {{
                          validationContext.errors[0]
                            ? handleError(validationContext.errors[0])
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col sm="6">
                <b-form-group :label="$t('profile.PhoneNumberinput')">
                <validation-provider
                #default="{ errors }"
                name="phoneNumber"
                rules="required"
                >
                <b-input-group
                class="input-group-merge phone-number-min-mb"
                :class="errors.length > 0 ? 'is-invalid' : null"
                >
                <b-input-group-prepend
                :class="errors.length ? 'is-invalid' : null"
                >
                <v-select
                id="vue-select"
                v-model="selectedCountry"
                :options="countryCodeList"
                :reduce="(option) => option.value"
                :clearable="false"
                lable="option"
                class="county-select"
                />
                </b-input-group-prepend>
                <b-form-input
                :class="errors.length ? 'is-invalid' : null"
                type="number"
                name="phoneNumber"
                v-model="user.mobile"
                :placeholder="$t('user.EnterMobile')"
                @keypress="validateNumber"
                />
                </b-input-group>


                <small class="text-danger">{{
                errors[0] ? handleError(errors[0]) : ""
                }}</small>
                </validation-provider>
                </b-form-group>
                </b-col> -->
                <b-col lg="6" class="input-ui-design">
                  <b-form-group :label="$t('profile.PhoneNumberinput')">
                    <validation-provider
                      name="mobile"
                      :rules="{
                        required: false
                      }"
                    >
                      <VuePhoneNumberInput
                        @update="countryChanged"
                        v-model="user.mobile"
                        :default-country-code="countryCode"
                        id="vue-select"
                        name="phoneNumber"
                        :options="countryCodeList"
                        :reduce="(option) => option.value"
                        :class="isMobileValid ? 'valid-phone' : ''"
                        :clearable="false"
                        :placeholder="$t('user.EnterMobile')"
                      />
                      <!-- <small class="text-danger">{{
                   errors[0] ? handleError(errors[0]) : ""
                  }}</small> -->
                      <div class="hassErrorcss">
                        <small class="text-danger">{{ hasError }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.TimeZone')"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                  :label="$t('profile.TimeZone') + '*'"
                  class="is-valid"
                >
                  <v-select
                    id="vue-select"
                    v-model="user.timezone"
                    :options="TZoption"
                    :reduce="(option) => option.value"
                    label="label"
                    class="time-h"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{
                      validationContext.errors[0]
                        ? handleError(validationContext.errors[0])
                        : ""
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->
                <b-col md="6">
                  <b-form-group :label="$t('profile.Notification.title')">
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="notification_status"
                        name="check-button"
                        switch
                        checked="true"
                        class="custom-control-success"
                        @change="(e) => updateNotification()"
                      >
                      </b-form-checkbox>
                      <AlertIcon
                        class="custom-icon notification-waring"
                        v-if="isNotificationBrowserSupported !== 1"
                        v-b-tooltip.hover.primary="{
                          title:
                            isNotificationBrowserSupported === 0
                              ? $t('profile.Notification.NotSupported')
                              : isNotificationBrowserSupported === 2
                              ? $t('profile.Notification.Denied')
                              : $t('profile.Notification.NotSupported'),
                          customClass: 'tooltip-notification-waring'
                        }"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  type="submit"
                  :disabled="invalid || !isMobileValid"
                >
                  {{ $t("profile.SaveChanges") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="mt-2"
                  @click="resetForm"
                >
                  {{ $t("profile.Discard") }}
                </b-button>
              </b-col>
            </b-row>
            <!-- <div class="hassErrorcss">
<small class="text-danger">{{ hasError }}</small>
</div> -->
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <Loader :show="showLoading" />
  </div>
</template>

<script>
import * as countries from "countries-phone-masks";
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import firebaseApp from "../../utils/firebase";
import { getMessaging, getToken } from "firebase/messaging";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import AuthService from "@/libs/api/auth-service";
import PushService from "@/libs/api/push-service";
import FileService from "@/libs/api/file-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AvatarImage from "@/assets/images/pages/avatar.svg";
import constants from "@/utils/constants";
import { mapActions, mapState, mapMutations } from "vuex";
import Loader from "@/layouts/components/Loader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { phone } from "phone";
import AlertIcon from "@/assets/images/icons/Alerts.svg";

import parsePhoneNumber from "libphonenumber-js";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import EventBus from "./../../EventBus.js";
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  components: {
    BButton,
    VuePhoneNumberInput,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    Loader,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    VueCropper,
    AvatarImage,
    BFormCheckbox,
    VBTooltip,
    AlertIcon
  },
  computed: {
    requiredState() {
      return (props) =>
        this.user[props] === null
          ? null
          : this.user[props]?.length > 2
          ? true
          : false;
    },
    nameState() {
      return (props) =>
        this.user[props] === null
          ? null
          : /^[a-zA-Z0-9]+$/.test(this.user[props]);
    },
    userEmailState() {
      return this.user.email === null
        ? null
        : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.user.email
          );
    },
    timeZoneState() {
      return null;
    }
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      hasError: "",

      countryCode: "",
      countryCallingCode: "+44",
      notification_status: null,
      push: null,
      user: {
        first_name: null,
        email: null,
        last_name: null,
        username: null,
        mobile: null,
        timezone: null,
        profile_pic: null,
        profile_pic_url: null
      },
      countryCodeList: countries || [],
      phone: "",
      isValidPhoneNumber: true,
      currentUser: null,
      optionsLocal: null,
      profileFile: null,
      TZoption: constants.TZStrings,
      required,
      email,
      refFormObserver,
      getValidationState,
      downloadUrl: process.env.VUE_APP_FILE_URL,
      showLoading: false,
      parsePhoneNumber: parsePhoneNumber,
      selectedCountry: null,
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      isNotificationBrowserSupported: 0,
      image: "",
      dialog: false,
      files: "",
      modalShow: false,
      isMobileValid: false
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.countryCodeList = this.countryCodeList.map((c) => {
      return { value: c.code, label: `${c.code} (${c.iso})` };
    });
    this.getUserProfile();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    countryCodeList(val) {
      if (val) {
        this.countryCodeList = val;
      }
    },
    profileFile(val) {
      this.profileFile = val;
    }
  },
  methods: {
    trimSpaces() {
      this.user.username = this.user.username.trim(); // Remove leading and trailing spaces
    },
    ...mapMutations("user", ["setUserAccount"]),
    validateNumber: (event) => {
      const charCode = String.fromCharCode(event.keyCode);
      if (!/^[0-9]$/.test(charCode)) {
        event.preventDefault();
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    resetForm() {
      this.profileFile = null;
      this.user = Object.assign({}, this.currentUser);
      let phoneNumber = this.parsePhoneNumber(this.user.mobile);
      this.selectedCountry = "+" + phoneNumber.countryCallingCode;

      this.user.mobile = phoneNumber.nationalNumber;
      this.notification_status =
        localStorage.getItem("notificationToken") && this.user.push_notification
          ? this.user.push_notification.status
          : false;
      this.$router.go(-1);
    },
    resetImg() {
      this.profileFile = null;
      this.user.profile_pic = this.currentUser?.profile_pic || null;
      this.user.profile_pic_url = this.currentUser?.profile_pic_url || null;
    },
    async getPushToken(userProfileFlag) {
      const messaging = getMessaging(firebaseApp);
      navigator.serviceWorker
        .register("/app/firebase-messaging-sw.js")
        .then((serviceWorkerRegistration) => {
          return getToken(messaging, {
            vapidKey:
              "BG2plIQHsAObZUznnNLjC5nYXFDmC6inHatRvnbjccuagsPpcwkKibJT2adzWiBdAlgb4_cCzfmRShivYzVe4aY",
            serviceWorkerRegistration
          });
        })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("notificationToken", currentToken);
          }
        })
        .catch((err) => {
          console.log("error while notification token:", err.message);
          localStorage.removeItem("notificationToken");
          if (!userProfileFlag) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("profile.Notification.title"),
                text: err.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        });
    },
    updateNotification() {
      if ("Notification" in window) {
        if (!Notification || Notification.permission != "granted") {
          if (this.user.notification_status) {
            this.notification_status = true;
          } else {
            this.notification_status = false;
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("profile.Notification.Denied"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } else {
        if (this.user.notification_status) {
          this.notification_status = true;
        } else {
          this.notification_status = false;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "",
            text: this.$t("profile.Notification.NotSupported"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getUserProfile() {
      try {
        this.showLoading = true;
        let response = await new AuthService().getUserProfile({});
        this.showLoading = false;
        if (response && response.data) {
          this.currentUser = response.data;
          this.user = Object.assign({}, response.data);
          let phoneNumber = this.parsePhoneNumber(this.user.mobile);
          const mobileDetailsCheck = phone(this.user.mobile);
          if (mobileDetailsCheck && mobileDetailsCheck.countryIso2) {
            const mobileDetails = phone(this.user.mobile, {
              country: mobileDetailsCheck.countryIso2
            });
            this.countryCode = mobileDetailsCheck.countryIso2 || "IN";
            this.user.mobile = mobileDetails.phoneNumber;
            this.mobileNo = mobileDetailsCheck.phoneNumber.replace(
              mobileDetailsCheck.countryCode,
              ""
            );
          }

          this.notification_status = this.currentUser
            ? this.currentUser.notification_status
            : false;

          if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                this.isNotificationBrowserSupported = 1;
              } else {
                this.isNotificationBrowserSupported = 2;
              }
            });
          } else {
            this.isNotificationBrowserSupported = 0;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    countryChanged(e) {
      let me = this;
      let hasErr = "";

      if (e.formattedNumber) {
        const mobileDetailsCheck = phone(e.formattedNumber);
        if (!mobileDetailsCheck || !mobileDetailsCheck.countryIso2) {
          me.hasError = me.$t("errors.invalidMobile");
          this.isMobileValid = false;

          return;
        }
        const mobileDetails = phone(e.formattedNumber, {
          country: mobileDetailsCheck.countryIso2
        });
        this.selectedCountry =
          (mobileDetails && mobileDetails.countryIso2) || null;
        this.countryCallingCode = mobileDetails && mobileDetails.countryCode;
        if (
          !mobileDetails ||
          !mobileDetails.isValid ||
          !mobileDetails.countryCode
        ) {
          this.isMobileValid = false;
          hasErr = me.$t("errors.invalidMobile");
        }
        if (mobileDetails.isValid) {
          this.isMobileValid = true;
        } else {
          this.isMobileValid = false;
        }
      }
      this.user.mobile = e.formattedNumber;
      if ((hasErr && hasErr !== "") || !this.user.mobile) {
        if (!this.user.mobile) {
          hasErr = this.$t("profile.MobileRequired");
        }
        me.hasError = hasErr;
        this.isMobileValid = false;
        return;
      } else {
        me.hasError = "";
        this.isMobileValid = true;
        return;
      }
    },

    async updateUserProfile() {
      let hasError = "";
      let phoneNumber = this.user.mobile;
      phoneNumber = phoneNumber.replace(/[\s\(\)-]/g, "");
      phoneNumber = phoneNumber.replace(/[\(\)-]/g, "");
      this.user.mobile = this.countryCallingCode + "" + phoneNumber;

      const mobileDetailsCheck = phone(this.user.mobile, {
        country: this.selectedCountry
      });
      if (!mobileDetailsCheck || !mobileDetailsCheck.countryIso2) {
        this.hasError = this.$t("errors.invalidMobile");
        return;
      }
      const mobileDetails = phone(this.user.mobile, {
        country: mobileDetailsCheck.countryIso2
      });

      if (!this.selectedCountry || !parseInt(this.user.mobile)) {
        hasError = this.$t("errors.invalidMobile");
      } else if (!mobileDetails || !mobileDetails.isValid) {
        hasError = this.$t("errors.invalidMobile");
      } else {
        this.hasError = hasError;
      }
      if (hasError && hasError !== "") {
        this.hasError = hasError;
        return;
      }
      try {
        // if (JSON.stringify(this.user) === JSON.stringify(this.currentUser)) {
        //   return;
        // }
        this.showLoading = true;
        let response = await new AuthService().updateUserProfile({
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          timezone: this.user.timezone || undefined,
          profile_pic: this.user.profile_pic || undefined,
          profile_pic_url: this.user.profile_pic_url || undefined,
          email: this.user.email.toLowerCase(),
          mobile: mobileDetails.phoneNumber,
          username: this.user.username.toLowerCase(),
          notification_status: this.notification_status
        });
        this.showLoading = false;
        if (response && response.data && response.data.success) {
          this.currentUser = Object.assign({}, this.user);
          this.setUserAccount(Object.assign({}, this.user));
          localStorage.setItem(
            "USER_PROFILE_DETAILS",
            JSON.stringify(Object.assign({}, this.user))
          );
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.UpdateProfile"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          // this.getUserProfile();
          EventBus.$emit("data-updated", this.user);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async saveImage() {
      this.modalShow = false;
      const fileName =
        (this.profileFile && this.profileFile.name) ||
        Math.random().toString(36).slice(2, 7);
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let newValue = this.dataURLtoFile(this.cropedImage, fileName);
      if (newValue) {
        this.showLoading = true;
        let data = new FormData();
        let file = newValue;

        data.append("name", fileName);
        data.append("file", file);
        new FileService()
          .fileApi(data)
          .then((res) => {
            if (res && res.success) {
              this.user.profile_pic = res.code;
              this.user.url = res.url;
              this.modalShow = false;
            } else if (res && !res.success && res.err && res.err.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.err.name,
                  text: res.err.message,
                  icon: "EditIcon",
                  variant: "error"
                }
              });
            }
            this.showLoading = false;
          })
          .catch((err) => {
            this.showLoading = false;
            this.showModal = false;
          });
      }
    },
    async cancelImage() {
      this.modalShow = false;
    },

    onFileSelect(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.mime_type = file.type;
      var fileSize = Math.round(file.size / 1024);

      if (fileSize >= constants.MAX_FILE_SIZE_LIMIT * 1024) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "",
            text: this.$t("profile.fileUploadLimitMessage", {
              limit: constants.MAX_FILE_SIZE_LIMIT
            }),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return;
      }
      if (
        file &&
        file.type &&
        !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        setTimeout(() => {
          this.resetImg();
        }, 200);

        this.$toast({
          component: ToastificationContent,
          props: {
            text: this.$t("errors.AllowedImagesError"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });

        return false;
      }
      this.modalShow = true;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
        this.$bvModal.show("image-upload");
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("unit.FileReaderNotSupported"),
            text: this.$t("unit.FileReaderNotSupported"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    };
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.county-select .vs__search {
  min-width: 100px;
}
.phone-number-min-mb {
  .vs__dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .vs__selected-options {
    min-width: 167px;
  }
}
.phone-number-min-mb.input-group-merge {
  .input-group-prepend:after {
    right: 0px !important;
  }
}
.profile-pic {
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
.tooltip-notification-waring .tooltip-inner {
  min-width: 150px !important;
}
.profile-back {
  position: relative;
  .back-btn-update-profile {
    text-align: right;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .card-title {
    margin-top: 0px;
    margin-bottom: 15px !important;
  }
}
@media only screen and (max-width: 820px) {
  .profile-back {
    .back-btn-update-profile {
      right: 15px;
      top: 9px;
    }
    .card-title {
      margin-top: 5px;
      margin-bottom: 25px !important;
    }
  }
  .time-h {
    .vs__selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 46px);
    }
    .vs__actions {
      margin-left: -46px;
    }
    .vs__dropdown-menu {
      max-height: 23vh;
    }
    input {
      height: 15px;
    }
  }
  .tooltip-notification-waring .tooltip-inner {
    min-width: 200px !important;
  }
}
.hassErrorcss {
  margin-top: 10px;
}
.imgtext {
  width: 60vw;
}
</style>
<style scoped>
.custom-control-success.custom-control.custom-switch {
  z-index: 0;
}
.notification-waring {
  margin-top: -29px;
  margin-left: 55px;
}
.profile-scroll {
  height: calc(100vh - 380px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
